
.vertical-timeline-element {
  .tag-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
  .vertical-timeline-element-content {
    background-color: #fcfcfc;

    .ant-tag {
      color: white;
      border: 0;
      border-radius: 10px;
    }
  }
  .vertical-timeline-element-icon {
    background-color: #fcfcfc;
    span {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
.element--journal-intake {
  .vertical-timeline-element-content {
    border-top: 3px solid green;
    .ant-tag {
      background-color: green;
    }

    .vertical-timeline-element-content-arrow {
      border-right: 7px solid #fcfcfc;
    }

  }
}

.element--activity-chat-message {
  .vertical-timeline-element-content {
    border-top: 3px solid blue;

    .ant-tag {
      background-color: blue;
    }

    .vertical-timeline-element-content-arrow {
      border-right: 7px solid #fcfcfc;
    }

  }
}

.element--activity-lifesaver {
  .vertical-timeline-element-content {
    border-top: 3px solid peru;

    .ant-tag {
      background-color: peru;
    }

    .vertical-timeline-element-content-arrow {
      border-right: 7px solid #fcfcfc;
    }

  }
}

.element--user-badges {
  .vertical-timeline-element-content {
    border-top: 3px solid gold;

    .ant-tag {
      background-color: gold;
    }

    .vertical-timeline-element-content-arrow {
      border-right: 7px solid #fcfcfc;
    }

  }
}

.element--answer-reflection,
.element--activity-reflection,
.element--activity-audio,
.element--activity-2d-video,
.element--activity-vr-met {
  .vertical-timeline-element-content {
    border-top: 3px solid red;

    .ant-tag {
      background-color: red;
    }

    .vertical-timeline-element-content-arrow {
      border-right: 7px solid #fcfcfc;
    }

  }
}
.element--answer-emotion {
  .vertical-timeline-element-content {
    border-top: 3px solid brown;

    .ant-tag {
      background-color: brown;
    }

    .vertical-timeline-element-content-arrow {
      border-right: 7px solid #fcfcfc;
    }

  }
}

.element--user-quit-day {
  .vertical-timeline-element-content {
    border-top: 3px solid pink;

    .ant-tag {
      background-color: pink;
    }

    .vertical-timeline-element-content-arrow {
      border-right: 7px solid #fcfcfc;
    }

  }
}

.element--user-state {
  .vertical-timeline-element-content {
    border-top: 3px solid purple;

    .ant-tag {
      background-color: purple;
    }

    .vertical-timeline-element-content-arrow {
      border-right: 7px solid #fcfcfc;
    }

  }
}

.element--answer-onboarding {
  .vertical-timeline-element-content {
    border-top: 3px solid #666;

    .ant-tag {
      background-color: 666;
    }

    .vertical-timeline-element-content-arrow {
      border-right: 7px solid #fcfcfc;
    }

  }
}

.element--user-registration {
  .vertical-timeline-element-content {
    border-top: 3px solid black;

    .ant-tag {
      background-color: black;
    }

    .vertical-timeline-element-content-arrow {
      border-right: 7px solid #fcfcfc;
    }

  }
}