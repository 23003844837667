ul {
    padding: 0;
    margin:0;
    list-style-type: none;

    &.features-container {
    
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .feature-item {
        width: 50%;
        line-height: 18px;
        padding: 10px;
        >.icon {
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 40px;
          height: 40px;
          // border: 1px solid red;
          display: inline-block;
          float: left;
          margin-right: 9px;
        }

        &.app > .icon {
          background-image: url('/app.png');
        }

        &.h2h > .icon {
          background-image: url('/h2h.png');
        }

        &.dashboard > .icon {
          background-image: url('/dashboard.png');
        }

        &.science > .icon {
          background-image: url('/science.png');
        }
      }
    }
  }
